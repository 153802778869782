<template>
    <nav class="navbar navbar-expand-lg fixed-top navbar-custom navbar-light sticky-dark" id="navbar-sticky">
        <div class="container">
            <!-- LOGO -->
            <router-link to="/" class="logo text-uppercase">
                <!-- <img src="images/logo.png" alt="" class="logo-dark" /> -->
                <img src="images/logo.png" alt="" class="logo-light" style="width:129; height:100px;" />
            </router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
                aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <i class="mdi mdi-menu"></i>
            </button>
            <div class="collapse navbar-collapse" id="navbarCollapse">
                <ul class="navbar-nav mx-auto navbar-center" id="mySidenav">
                    <li class="nav-item">
                        <router-link to="/" class="nav-link">Home </router-link>
                    </li>
                    <li class="nav-item">
                        <!-- <a href="#features" class="nav-link">Features</a> -->
                        <router-link to="#" class="nav-link">About </router-link>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="nav-link">How it Work?</a>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="nav-link">Testimonial</a>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="nav-link">Pricing</a>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="nav-link">Contact Us</a>
                    </li>
                </ul>
                <ul class="navbar-nav navbar-center">
                    <li class="nav-item">
                        <a href="#" class="nav-link" data-bs-toggle="modal" data-bs-target="#loginModal">Login</a>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="btn btn-sm nav-btn" data-bs-toggle="modal" data-bs-target="#signupModal">Sign
                            Up</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <!-- home-agency start -->
    <section class="hero-1" id="home">
        <div class="bg-overlay-img"></div>
        <div class="container">
            <h1 class="hero-title fw-bold mb-4 pb-3 text-white">Search by VIN#</h1>
            <div class="col-lg-6">
                <div v-if="isLoading" class="spinner-border text-danger m-2" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <div v-if="isError" class="alert alert-danger" role="alert">
                    No Data Found Change the VIN
                </div>
                <div class="subscribe-form me-lg-5 mb-5 mb-lg-0">
                    <i class="mdi mdi-car form-icon"></i>
                    <!-- <input type="email" class="form-control" id="exampleFormControlInput1"
                        placeholder="Enter VIN Number" /> -->
                    <!-- <input v-model="vin" type="text" class="form-control" placeholder="Enter VIN number" /> -->
                    <input v-model="vin" type="text" name="Vin" class="form-control" placeholder="Enter VIN number" />
                    <button class="btn btn-success form-btn" @click="searchVehicleHistory">Search Now</button>

                    <!-- <a href="javascript:void(0);" class="btn btn-success form-btn"><span>Search Now</span>
                         <i class="mdi mdi-download download-icon"></i></a> -->
                </div>
            </div>
            <div class="row align-items-center hero-content">
                <div class="col-lg-6">

                    <p class="text-white opacity-75 mb-4 pb-3 fs-17">Maecenas tempus, tellus eget condimentum rhoncus
                        quam semper libero sit amet adipiscing sem neque sed ipsum nam quam nunc blandit vel luctus
                        pulvinar.</p>
                    <p class="text-white opacity-75 mb-4 pb-3 fs-17">Maecenas tempus, tellus eget condimentum rhoncus
                        quam semper libero sit amet adipiscing sem neque sed ipsum nam quam nunc blandit vel luctus
                        pulvinar.</p>
                    <p class="text-white opacity-75 mb-4 pb-3 fs-17">Maecenas tempus, tellus eget condimentum rhoncus
                        quam semper libero sit amet adipiscing sem neque sed ipsum nam quam nunc blandit vel luctus
                        pulvinar.</p>

                </div>
                <div class="col-lg-6 text-center text-lg-end">
                    <img src="images/heros/hero-1-img.png" alt="" style="height: 2%;" class="img-fluid" />
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="hero-bottom-shape">
                        <img src="images/heros/hero-1-bottom-shape.png" alt="" class="w-100" />
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="down-arrow-btn">
                        <a href="#how-it-work" class="down-arrow"><i class="mdi mdi-arrow-down"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>
<script>
export default {
    data() {
        return {
            vin: '',
            error: '',
            isLoading: false, // This will control the spinner visibility
            isError: false,
            SECRET_KEY: 'SINGLE@N3tV3h1c13@P!', // Your secret key for HMAC generation
        };
    },
    methods: {

        bytesToHex(bytes) {
            return Array.from(new Uint8Array(bytes))
                .map((b) => b.toString(16).padStart(2, '0'))
                .join('');
        },
        // Function to generate API key using crypto.subtle
        async generateApiKey(nonce) {
            const encoder = new TextEncoder();
            const key = await crypto.subtle.importKey(
                'raw',
                encoder.encode(this.SECRET_KEY), // Encode the secret key
                { name: 'HMAC', hash: 'SHA-256' }, // HMAC with SHA-256 algorithm
                false,
                ['sign'] // Only sign is allowed for this key
            );
            const signature = await crypto.subtle.sign(
                'HMAC',
                key,
                encoder.encode(nonce) // Generate signature using the nonce
            );
            return this.bytesToHex(signature); // Convert the signature to hex format
        },

        // Method that is triggered when Search button is clicked
        async searchVehicleHistory() {
            // Check if VIN is entered
            if (!this.vin) {
                console.log('VIN number is required');
                return;
            }

            // Generate nonce using current timestamp in milliseconds
            const nonce = String(Date.now());

            // Generate the API key using the nonce
            const apiKey = await this.generateApiKey(nonce);

            // Log the nonce and API key to the console for debugging
            console.log('Generated API Key:', apiKey);
            console.log('Generated Nonce:', nonce);

            // API URL for vehicle history
            const apiUrl = `https://9dhlhx7sl1.execute-api.eu-north-1.amazonaws.com/dev/vehicle-history/report-summary/${this.vin}?productCode=VIND`;
            this.isLoading = true; // Show loading spinner
            console.log("Sending Request to API : ",apiUrl);
            try {
                // Perform API request with the API key and nonce in headers
                const response = await fetch(apiUrl, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-API-KEY': apiKey,
                        'nonce': nonce,
                    }
                });
            //    console.log("After Request");
                // Log the raw response and status code
                const text = await response.text(); // Get the response as text
             //   console.log('Response Status:', response.status);
             //   console.log('Raw Response:', text); // Log the raw response

                // Check if the response is okay
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                // Manually parse the response if it's not JSON
                const data = this.parseVehicleReportResponse(text); // Use custom parser
             //   console.log('Parsed API Response:', data); // Log the parsed response

                // If data exists, store it in localStorage and navigate to the 'about' route
                if (data) {
                    localStorage.setItem('vehicleData', JSON.stringify(data)); // Save to local storage
                    this.$router.push({
                        name: 'checkout',
                    });
                }

            } catch (error) {
                console.error('Error fetching vehicle history:', error); // Log error if the API call fails
            } finally {
                this.isLoading = false; // Hide loading spinner
            }
        },

        // Custom parser for the VehicleReportResponse string
        parseVehicleReportResponse(responseText) {
            const data = {};
            // Regex to match key-value pairs
            const regex = /(\w+)=([^,]+)/g;
            let match;

            // Iterate over matches and populate the data object
            while ((match = regex.exec(responseText)) !== null) {
                const key = match[1].trim();
                const value = match[2].trim().replace(/^['"]|['"]$/g, ''); // Remove quotes
                data[key] = value === 'null' ? null : value; // Convert 'null' string to null
            }

            return data; // Return the parsed data object
        },


        // Previously how API was being hit
        async searchVehicleHistoryPrevious() {
            if (!this.vin) {
                console.log('VIN number is required');
                return;
            }
            const apiUrl = `https://zymn2c2dw1.execute-api.eu-north-1.amazonaws.com/dev/vehicle-history/${this.vin}`;
            this.isLoading = true; // Show the spinner

            try {
                const response = await fetch(apiUrl);
                const data = await response.json();
                console.log('API Response:', data);

                if (data) {
                    localStorage.setItem('vehicleData', JSON.stringify(data)); // Store data as a string
                    this.$router.push({
                        name: 'about',
                        params: { vehicleData: data }
                    });
                }

            } catch (error) {
                console.error('Error fetching vehicle history:', error);
            }
            finally {
                this.isLoading = false; // Hide the spinner
            }
        },

        async handleVINSearch() {
            if (this.vin.trim() === '') {
                alert('Please enter a VIN');
                return;
            }

            const fileNames = [
                'Classic-Vehicle.txt',
                'Commercial-Trailer.txt',
                'Heavy-truck.txt',
                'Ligjht-truck.txt',
                'Medium-Truck.txt',
                'Motor-cycle.txt',
                'Motorized-RB.txt',
                'Passenger-Car.txt',
                'Power-Sport.txt',
                'Towable-RV.txt'
            ];

            let vinFound = false;

            for (let fileName of fileNames) {
                const filePath = `./API/${fileName}`;
                console.log('Fetching file from:', filePath);

                try {
                    const response = await fetch(filePath);
                    const text = await response.text();

                    // Search for the VIN in the "titleInformation" section of the file
                    const jsonDataStartIndex = text.indexOf('Json : {');
                    const jsonData = text.substring(jsonDataStartIndex + 7).trim();

                    try {
                        const parsedData = JSON.parse(jsonData);

                        const vinInfo = parsedData.titleInformation.find(info => info.vin === this.vin);

                        if (vinInfo) {
                            console.log(`VIN ${this.vin} found in ${fileName}`);

                            // Save to local storage
                            localStorage.setItem('currentVin', this.vin);
                            localStorage.setItem(this.vin, JSON.stringify(parsedData));

                            vinFound = true;
                            this.$router.push({ name: 'checkout', params: { vin: this.vin } });
                            break; // Exit the loop as the VIN has been found
                        }
                    } catch (parseError) {
                        console.error('Failed to parse JSON data:', parseError);
                        continue; // Proceed to the next file
                    }
                } catch (error) {
                    console.error(`Error fetching or processing file ${fileName}:`, error);
                }
            }

            if (!vinFound) {
                alert('VIN not found in Json Response');
            }
        },

        // This was done when getting the response from files
        async handleVINSearch22() {
            if (this.vin.trim() === '') {
                alert('Please enter a VIN');
                return;
            }
            const filePath = './API/Ligjht-truck.txt';  // Search the input
            console.log('Fetching file from:', filePath);

            try {
                const response = await fetch(filePath);
                const text = await response.text();
                console.log('File content:', text);

                // Extract the VIN from the link line
                const regex = new RegExp(`https://www.vindata.com/report/.*${this.vin}.*`, 'i');
                const match = regex.exec(text);
                console.log('Regex match:', match);

                if (match) {
                    const jsonDataStartIndex = text.indexOf('Json : {');
                    const jsonData = text.substring(jsonDataStartIndex + 7).trim(); // Extract JSON data
                    console.log('Extracted JSON data:', jsonData);

                    // Parse and store in local storage
                    try {
                        const parsedData = JSON.parse(jsonData);
                        localStorage.setItem('currentVin', this.vin); // Store VIN
                        localStorage.setItem(this.vin, JSON.stringify(parsedData)); // Store JSON data

                        // localStorage.setItem(this.vin, JSON.stringify(parsedData));
                        console.log('Data stored in localStorage:', parsedData);
                    } catch (parseError) {
                        console.error('Failed to parse JSON data:', parseError);
                        alert('There was an issue processing the vehicle data.');
                        return;
                    }

                    // Redirect to VehicleDetails page
                    this.$router.push({ name: 'checkout', params: { vin: this.vin } });
                } else {
                    alert('VIN not found in the provided file.');
                }
            } catch (error) {
                console.error('Error fetching or processing the file:', error);
            }
        },

    }
};
</script>

<template>
            <div class="row">

        <Nav_Sec />
                    <div class="col-lg-12 col-sm-10 col-md-12">
                        <div class="card mt-5">
                            <div class="card-body">
                                <h5 class="text-danger">Payment Procecing Failed</h5>
                           
                                <div role="alert" class="alert text-danger bg-white border alert-dismissible">
                                    <div class="d-flex align-items-start">
                                        <span class="badge bg-danger-subtle text-danger align-self-center me-3">Ohh no!</span>
                                        <div class="w-100">Payment Failed to process Kindly retry!!</div>
                                    </div>
                                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </div>
                                <center>
                                    <a href="/" type="button" class="btn btn-primary btn-lg btn-block">Search VIN Again</a>

                                </center>
                            </div>
                        </div>
                    </div><!-- end col-12 -->
        <Footer/>
        </div>
</template>

<script>
import Footer from '@/components/Footer.vue';
import Nav_Sec from '@/components/Nav_Sec.vue';

export default {
    components: {
        Footer, Nav_Sec,
    },

}
</script>
<template>
    <Nav_Sec />
    <!-- contact start -->
    <div class="space"></div>
    <section class="section bg-light" id="contact">
        <div class="container">
            <div class="row justify-content-center top_message">
                <div class="col-md-8 col-lg-6 col-sm-12 text-center">
                    <h6 class="subtitle">Check <span class="fw-bold"> Out</span></h6>
                    <h2 class="title">Pay Instantly & Get Access</h2>
                    <p class="text-muted">Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium
                        doloremque rem aperiam.</p>
                </div>
                <center>
                    <hr style="width:50%; color:green">
                </center>
            </div>
            <div class="row align-items-center" v-if="vehicleData">
                <div :class="vehicleData ? 'col-lg-6 col-sm-12' : 'col-lg-12 col-sm-12'">
                    <div class="card contact-form rounded-lg">
                        <div v-if="vehicleData" class="p-3"
                            :style="{ backgroundColor: vehicleData ? 'green' : 'red', color: 'white' }">
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0">
                                    <img src="images/check-hand-complete-success.svg" alt="..." height="80"
                                        style="margin-top: -5px; margin-left: 80px;" />
                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <span>
                                        <h2 class="mb-0">Success Data Found</h2>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div v-else class="p-3" :style="{ backgroundColor: 'red', color: 'white' }">
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0">
                                    <img src="images/fail.svg" alt="..." height="80"
                                        style="margin-top: -5px; margin-left: 80px;" />
                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <span>
                                        <h2 class="mb-0">Error !Data Not Found</h2>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-5">

                            <div class="d-flex mb-5">

                                <div class="flex-grow-1 ms-3">
                                    <div v-if="vehicleData">
                                        <h2 class="mb-0" style="color:green;">Success! </h2>
                                        <span>
                                            <h4 class="mb-0">We Have VINData Records for Your VIN</h4>
                                        </span>
                                    </div>
                                    <div v-else style="margin-top:-90px;">
                                        <h2 class="mb-0" style="color:red;">Error! </h2>
                                        <span>
                                            <h4 class="mb-0">Sorry No VINData Records for Your VIN</h4>
                                        </span>
                                        <a href="/" class="btn btn-danger">Search Again</a>
                                    </div>

                                </div>
                            </div>

                            <div class="mb-4">
                                <div class="d-flex align-items-center mb-3">
                                    <div class="flex-shrink-0">
                                        <div class="contact-icon bg-soft-primary text-primary">
                                            <i class="mdi mdi-car"></i>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <h5 class="mb-0 fs-18">{{ vehicleData.year }} {{ vehicleData.make }} {{
                                            vehicleData.model }}</h5>

                                    </div>
                                </div>
                                <p class="mb-1"><i class="mdi mdi-arrow-right-thin text-muted me-1"></i><a href="#"
                                        class="text-primary">VIN : {{ vehicleData.vin }}</a></p>
                            </div>
                            <div class="mb-4">
                                <div class="d-flex align-items-center mb-3">
                                    <div class="flex-shrink-0">
                                        <div class="contact-icon bg-soft-primary text-primary">
                                            <i class="mdi mdi-barcode"></i>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <img src="images/333.png" alt="" srcset="">
                                    </div>
                                </div>
                            </div>
                            <div class="mb-4">
                                <div class="d-flex align-items-center mb-3">
                                    <div class="flex-shrink-0">
                                        <div class="contact-icon bg-soft-primary text-primary">
                                            <i class="mdi mdi-star"></i>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <h2></h2>
                                        <h5 class="mb-0 fs-18">What People Say</h5>

                                    </div>
                                </div>
                            </div>
                            <div class="card"
                                style="background-color: #F5F5F5; height:100%; width: 100%; padding-top: 30px; padding-bottom: 30px;">
                                <div class="flex-grow-1 ms-3">
                                    <h5 class="mb-0 fs-18">"Before finding VINData I almost bought a car that was
                                        salvaged. I’m
                                        sure glad I found out the real history before making a bad decision"
                                    </h5>
                                    <center>
                                        <h5 class="fs-16 mb-2 text-primary">- Todd R.
                                            <i class="mdi mdi-star me-1" style="color:#FDB812"></i>
                                            <i class="mdi mdi-star me-1" style="color:#FDB812"></i>
                                            <i class="mdi mdi-star me-1" style="color:#FDB812"></i>
                                            <i class="mdi mdi-star me-1" style="color:#FDB812"></i>
                                            <i class="mdi mdi-star me-1" style="color:#FDB812"></i>
                                        </h5>
                                    </center>
                                </div>
                                <hr>

                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <div class="card contact-form rounded-lg">
                        <div class="p-3" style="background-color: #1F92C2; color:white; ">
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0 ">
                                    <img src="images/iconizer-Cashier Machine-07.svg" alt="..." height="80"
                                        style="margin-top: -5px; margin-left: 80px;" />
                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <span>
                                        <h2 class="mb-0">Secure checkout</h2>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-5 mb-5">
                            <p class="mb-5">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ullam, nulla harum
                                esse atque ex doloremque neque repellendus quod nemo! Reiciendis fugit totam eaque
                                expedita sequi autem minus dolor corrupti sed.
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod quas, rem reprehenderit
                                dignissimos voluptatum placeat autem. Ullam debitis ad mollitia ipsam tempora
                                accusantium quod quasi, totam soluta amet voluptates ea.
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod quas, rem reprehenderit
                                dignissimos voluptatum placeat autem. Ullam debitis ad mollitia ipsam tempora
                                accusantium quod quasi, totam soluta amet voluptates ea.
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod quas, rem reprehenderit
                                dignissimos voluptatum placeat autem. Ullam debitis ad mollitia ipsam tempora
                                accusantium quod quasi, totam soluta amet voluptates ea.
                            </p>
                            <stripe-checkout ref="checkoutRef" mode="payment" :pk="publishableKey"
                                :line-items="lineItems" :success-url="successURL" :cancel-url="cancelURL"
                                 @loading="v => loading = v" />
                            <button @click="submit" type="button" class="btn btn-primary btn-lg btn-block">Pay Now</button> 
                            <!-- <button type="button" @click="redirectToPaymentSuccess" class="btn btn-warning btn-sm">
                                Pay Now</button> -->
                        </div>

                    </div>
                </div>
            </div>
            <div class="row align-items-center" v-else>
                <div class="col 12">
                    <center>
                        <h1>No Records Found </h1>
                        <p>
                            There were no records found for VIN: EXPERT. Please verify the VIN and try again.
                            If the VIN is correct, the VIN/vehicle is not in the NVMTIS database and we recommend that
                            you contact your state directly for more information.
                        </p>
                        <a href="/" type="button" class="btn btn-danger btn-lg btn-block">
                            Try Another VIN!
                        </a>
                    </center>
                </div>

            </div>
        </div>
    </section>
    <!-- contact end -->
    <Footer />
</template>

<script>
import Footer from '@/components/Footer.vue';
import Nav_Sec from '@/components/Nav_Sec.vue';
import { StripeCheckout } from '@vue-stripe/vue-stripe';

export default {
    data() {
        this.publishableKey = "pk_test_51Nsc8GBK8mZ1uB0MyUE6fMrxdTkndmVL3cxWsGHsjp1s6E9DsMcmYDXaxHkjdQgBZmcvUYJhvw9ULmp73jUqWNJ600Am94S8mj";
        return {
            lineItems: [
                {
                    price: 'price_1Q2HwiBK8mZ1uB0Mx91RsPcJ', // The id of the one-time price you created in your Stripe dashboard
                    quantity: 1,
                },

            ],
// For Local Host Uncomment this
        //   successURL: 'http://localhost:8080/#/payment_process_success',
        //  cancelURL: 'http://localhost:8080/#/payment_process_failed',
// For Deployment UnComment this
       successURL: 'https://webdevelopmentdemo.online/#/payment_process_success',
      cancelURL: 'https://webdevelopmentdemo.online/#/payment_process_failed',

            vehicleData: null,
            loading: false,
        };
    },
    components: {
        Footer, Nav_Sec, StripeCheckout,
    },
    created() {
        this.fetchVehicleData();
        // this.logLocalStorageData();

    },
    methods: {
        async redirectToPaymentSuccess() {
            try {
                this.$router.push({ path: '/payment_process_success' });
            } catch (error) {
                console.error('Failed to redirect:', error);
            }
        },
        submit() {
            // You will be redirected to Stripe's secure checkout page
            this.$refs.checkoutRef.redirectToCheckout(this.lineItems);
        },

        fetchVehicleData() {
            //  console.log("Checking local storage for vehicle data...");

            // Get the vehicle data from local storage
            const storedData = localStorage.getItem("vehicleData");

            // If data exists, parse it and set it to vehicleData
            if (storedData) {
                try {
                    this.vehicleData = JSON.parse(storedData);
                    //       console.log("Retrieved vehicle data:", this.vehicleData);
                } catch (error) {
                    //      console.error("Error parsing vehicle data:", error);
                    this.vehicleData = null; // Reset if parsing fails
                }
            } else {
                //   console.log("No vehicle data found in local storage.");
                this.vehicleData = null; // No data found
            }
        }

    },
};
</script>

<style scoped>
@media (max-width: 767px) {

    /* Mobile and tablet screens */
    .top_message {
        margin-bottom: 7rem;
        /* Adjust this value as needed */
    }

}
</style>

<template>
    <Nav_Sec />
    <!-- contact start -->
    <!-- How it work start -->
    <section class="section">
        <div class="container">
            <div v-if="isLoading" class="loading-container">
                <div class="loading" role="status"></div>
                <span class="loading-text">{{ loadingMessage }}</span> 
            </div>
            <div class="row justify-content-center mb-5">
                <div class="col-md-8 col-lg-6 text-center">
                    <h6 class="subtitle text-success">Payment <span class="fw-bold">Successful</span></h6>
                    <h2 class="title">Generate Report Now..</h2>
                    <p class="text-muted">Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium
                        doloremque rem aperiam.</p>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4">
                    <div class="work-box px-lg-5 text-center mb-5 mb-lg-0">
                        <div class="work-icon bg-soft-primary mb-4">
                            <i class="mdi mdi-magnify-plus"></i>
                        </div>
                        <h5 class="fw-semibold">1. Search VIN</h5>
                        <p class="text-muted">You have Searched VIN <span class="fw-bold"> {{ vehicleData.vin }} </span>
                        </p>
                        <img src="images/arrow-top.png" alt="" class="work-arrow" />
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="work-box px-lg-5 text-center mb-5 mb-lg-0">
                        <div class="work-icon bg-soft-success mb-4">
                            <i class="mdi mdi-credit-card"></i>
                        </div>
                        <h5 class="fw-semibold">2. Payment </h5>
                        <p class="text-muted">Payment Done for the Report of Vehicle: <br><span class="fw-bold"> {{
                            vehicleData.year }} {{ vehicleData.make }} {{
                                    vehicleData.model }}</span> </p>
                        <img src="images/arrow-bottom.png" alt="" class="work-arrow" />
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="work-box px-lg-5 text-center mb-5 mb-lg-0">
                        <div class="work-icon bg-soft-warning mb-4">
                            <i class="mdi mdi-file-document"></i>
                        </div>
                        <h5 class="fw-semibold">3. Generate Report</h5>
                        <p class="text-muted">
                            <button type="button" @click="searchVehicleHistory" class="btn btn-warning btn-sm">Get
                                Report</button>
                        </p>
                    </div>
                </div>
            </div>
    
        </div>
    </section>
    <!-- How it work end -->

    <!-- contact end -->
    <Footer />
</template>

<script>
import Footer from '@/components/Footer.vue';
import Nav_Sec from '@/components/Nav_Sec.vue';

export default {
    data() {
        return {
            isLoading: false,
            vehicleData: localStorage.getItem('vehicleData') || {}, // Assuming vehicleData is stored in localStorage
            loading: false,
            SECRET_KEY: 'SINGLE@N3tV3h1c13@P!', // Your secret key for HMAC generation

            loadingMessage: 'Loading vehicle data...',  // Initial loading message
            loadingMessages: [
                'Loading vehicle data...',
                'Loading otherInformation...',
                'Loading recalls...',
                'Loading titleInformation...',
                'Loading odometerInformation...'
            ],
            loadingInterval: null,  // Store the interval reference

        };
    },
    components: {
        Footer, Nav_Sec,
    },
    created() {
        this.fetchVehicleData();
    },
    methods: {
        startLoadingMessages() {
            let messageIndex = 0;

            // Change the loading message every 4 seconds
            this.loadingInterval = setInterval(() => {
                this.loadingMessage = this.loadingMessages[messageIndex];
                messageIndex = (messageIndex + 1) % this.loadingMessages.length;
            }, 2000);
        },
        stopLoadingMessages() {
            this.isLoading = false;
            clearInterval(this.loadingInterval);  // Stop the interval
        },
        bytesToHex(bytes) {
            return Array.from(new Uint8Array(bytes))
                .map((b) => b.toString(16).padStart(2, '0'))
                .join('');
        },
        // Function to generate API key using crypto.subtle
        async generateApiKey(nonce) {
            const encoder = new TextEncoder();
            const key = await crypto.subtle.importKey(
                'raw',
                encoder.encode(this.SECRET_KEY), // Encode the secret key
                { name: 'HMAC', hash: 'SHA-256' }, // HMAC with SHA-256 algorithm
                false,
                ['sign'] // Only sign is allowed for this key
            );
            const signature = await crypto.subtle.sign(
                'HMAC',
                key,
                encoder.encode(nonce) // Generate signature using the nonce
            );
            return this.bytesToHex(signature); // Convert the signature to hex format
        },
        fetchVehicleData() {
            // console.log("Checking local storage for vehicle data...");

            // Get the vehicle data from local storage
            const storedData = localStorage.getItem("vehicleData");
            // If data exists, parse it and set it to vehicleData
            if (storedData) {
                try {
                    this.vehicleData = JSON.parse(storedData);
                    // console.log("Retrieved vehicle data:", this.vehicleData);
                } catch (error) {
                    console.error("Error parsing vehicle data:", error);
                    this.vehicleData = null; // Reset if parsing fails
                }
            } else {
                console.log("No vehicle data found in local storage.");
                this.vehicleData = null; // No data found
            }
        },
        // Method that is triggered when Search button is clicked
        async searchVehicleHistory() {
            this.isLoading = true;
            this.startLoadingMessages();
            // console.log('Retrieved vehicleData:', this.vehicleData);
            // Check if VIN exists and is not undefined or empty
            if (!this.vehicleData || !this.vehicleData.vin) {
                console.error('VIN is missing or undefined in vehicleData');
                return;
            }

            // Generate nonce using current timestamp in milliseconds
            const nonce = String(Date.now());

            // Generate the API key using the nonce
            const apiKey = await this.generateApiKey(nonce);

            // Log the nonce and API key to the console for debugging
            // console.log('Generated API Key:', apiKey);
            // console.log('Generated Nonce:', nonce);

            // Keep the loader active for 20 seconds regardless of API response time
            setTimeout(() => {
                this.stopLoadingMessages(); // Stop the loader after 20 seconds
            }, 10000);

            // API URL for vehicle history
            const apiUrl = `https://9dhlhx7sl1.execute-api.eu-north-1.amazonaws.com/dev/vehicle-history/${this.vehicleData.vin}`;

            // console.log("Send Request to API : ", apiUrl);
            this.isLoading = true; // Show loading spinner
            // console.log('Calling API with VIN:', this.vehicleData.vin);
            // console.log('Before Try');

            try {
                // Perform API request with the API key and nonce in headers
                const response = await fetch(apiUrl, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-API-KEY': apiKey,
                        'nonce': nonce,
                    },
                });
                // console.log('Inside Try');
                // Log the raw response and status code
                // console.log('Response Status:', response.status);

                if (!response.ok) {
                    console.log('The API Response failed with error', response);
                }

                // Check if the response is okay
                if (response.ok) {
                    const data = await response.json();

                    // console.log('Response Data:', data);  // Log the parsed JSON data

                    // Store the data in localStorage
                    localStorage.setItem('vehicleData', JSON.stringify(data));
                    // Redirect to the about page
                    this.$router.push('/about');
                } else {
                    console.error('Failed to fetch vehicle history:', response.statusText);
                }

            } catch (error) {
                console.error('Error fetching vehicle history:', error); // Log error if the API call fails
            } finally {
                // this.isLoading = false; 
                // Hide loading spinner
            }
        },

   

    },
};
</script>

<style scoped>
.loading-container {
    display: flex;
    flex-direction: column;
    /* Stack the loader and message vertically */
    align-items: center;
    /* Center them horizontally */
    justify-content: center;
    /* Center them vertically */
    min-height: 200px;
    margin-top: 100px;
}

/* Absolute Center Spinner */
.loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    font-size: 20px;
    /* Ensure the font size is visible */
    color: #333;
    /* Ensure the text color is visible */
    background-color: rgba(255, 255, 255, 0.8);
    /* Light background for better contrast */

}

.loading-text {
    font-weight: bold;
    color: #000;
    align-items: center;
    /* Center them horizontally */
    justify-content: center;
    /* Center them vertically */
    /* Ensure the text color stands out */
}

/* Transparent Overlay */
.loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(rgba(20, 20, 20, .8), rgba(0, 0, 0, .8));
    background: -webkit-radial-gradient(rgba(20, 20, 20, .8), rgba(0, 0, 0, .8));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.loading:not(:required):after {
    content: '';
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    -webkit-animation: spinner 150ms infinite linear;
    -moz-animation: spinner 150ms infinite linear;
    -ms-animation: spinner 150ms infinite linear;
    -o-animation: spinner 150ms infinite linear;
    animation: spinner 150ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
    box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-o-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
</style>
